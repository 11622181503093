import React, { useEffect, useRef, useState } from 'react'
import { useAccount } from 'wagmi';
import { MULTISENDER_CHAIN, ZERO_ADDRESS, contract } from '../helper/constant';
import { getContract } from '../helper/contractHelper';
import multisendAbi from '../json/multisender.json';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { useDispatch, useSelector } from 'react-redux';
import { addTxHash } from '../redux/slices/MultisenderSlice';
import Transactions from './Transactions';
import { useLocation } from 'react-router-dom';

export default function SenderStep4() {
    const { address , chain } = useAccount();
    const signer = useEthersSigner()
    const isCalledRef = useRef(false);
    const dispatch = useDispatch();


    const chunkAddress = useSelector(state => state.multisender.chunkAddress);
    const chaunkAmounts = useSelector(state => state.multisender.chaunkAmounts);
    const txhash = useSelector(state => state.multisender.txhash);
    const tokenType = useSelector(state => state.multisender.tokenType)
    const tokenAddress = useSelector(state => state.multisender.tokenAddress);
    const numberoftransaction = useSelector(state => state.multisender.numberoftransaction);
    const totalTokenSent = useSelector(state => state.multisender.totalTokenSent);
    const isExcluded = useSelector(state => state.multisender.isExcluded);
    const hasUnsetTxhash = txhash.length === chunkAddress.length && txhash.some(obj => !obj.hash || obj.status === 3);
    const hasCompleteTxhash = txhash.length === chunkAddress.length && txhash.some(obj => obj.status === 1);

    const [success, setSuccess] = useState();
    const [resend, setResend] = useState(Math.random())
    const sleep = ms => {
        return new Promise(resolve => setTimeout(resolve, ms))
    }



    useEffect(() => {
        if (address) {
            if (chain && chain.id && MULTISENDER_CHAIN.includes(chain.id)) {
                if (!isCalledRef.current && signer) {
                    isCalledRef.current = true;
                    handleSubmit()
                }
            }
            else {
                toast.error('Please switch to supported network');
            }
        }
        else {
            toast.error('Please connect wallet');
        }
        // eslint-disable-next-line
    }, [address, chain, signer, resend]);


    const handleSubmit = async () => {
        try {
            let multisenderContract = getContract(multisendAbi, contract[chain.id].MULTISENDER_ADDRESS, signer);
            let fees = await multisenderContract.charges();


            async function* asyncGenerator() {
                let i = 0;
                while (i < chunkAddress.length) {
                    yield i++;
                }
            }

            for await (const num of asyncGenerator()) {
                if ((txhash[num] === 'undefined' || txhash[num] === undefined || txhash[num].hash === '' || txhash[num].status === 5)) {
                    try {
                        let tx;
                        if (!tokenAddress) {
                            let totalToken  = ethers.utils.parseEther(totalTokenSent.toFixed(18).toString())
                            fees = fees.add(totalToken);
                        }
                        if (tokenType === 3) {
                            tx = await multisenderContract.sendNft(
                                tokenAddress ? tokenAddress : ZERO_ADDRESS,
                                chunkAddress[num],
                                chaunkAmounts[num],
                                {
                                    value: fees,
                                    from: address
                                }
                            );
                        }
                        else if(tokenType === 2) {
                            if(isExcluded){
                                tx = await multisenderContract.sendTokenSimple(
                                    tokenAddress ? tokenAddress : ZERO_ADDRESS,
                                    chunkAddress[num],
                                    chaunkAmounts[num],
                                    {
                                        value: fees,
                                        from: address
                                    }
                                );
                            }
                            else{
                                tx = await multisenderContract.sendToken(
                                    tokenAddress ? tokenAddress : ZERO_ADDRESS,
                                    chunkAddress[num],
                                    chaunkAmounts[num],
                                    {
                                        value: fees,
                                        from: address
                                    }
                                );
                            }
                        }
                        else{
                            console.log(fees.toString())
                            tx = await multisenderContract.sendNative(
                                chunkAddress[num],
                                chaunkAmounts[num],
                                {
                                    value: fees,
                                    from: address
                                }
                            );
                        }

                        dispatch(addTxHash({ id: num, hash: tx.hash, status: 2, error: '' }))
                        if (num === (chunkAddress.length - 1)) {
                            setSuccess(true);
                        }
                        await sleep(3000).then();
                    }
                    catch (err) {
                        console.log(err.message);

                        dispatch(addTxHash({ id: num, hash: '', status: 3, error: err.reason ? err.reason : err.message }));
                        if (num === (chunkAddress.length - 1)) {
                            setSuccess(true);
                        }
                    }
                }
            }
        }
        catch (err) {
            console.log(err.message)
        }
    }

    return (
        // <div className='bg-body-card max-w-3xl mt-1 mx-auto rounded-3 rounded-xl border-input'>
        //     <div className='p-0 p-xl-5'>
        <>
            <div class="row mb-4">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mb-3">
                        <h3 className='text-white text-bold mt-2 mt-md-0 text-center'>Total <span className='text-warning'>{numberoftransaction} transaction </span></h3>
                        <label class="form-label text-sm font-color">Please confirm the transaction to complete the amount specified above. Please do not refresh or close the page. until the transaction is completed</label>
                        {txhash && txhash.length > 0 && txhash.map((items, key) => {
                            return <Transactions index={key} />
                        })}
                    </div>
                    {success &&
                        <article class="notification is-success">Congratulations, your bulksend transactions were sent out successfully</article>
                    }
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 d-md-flex justify-content-center">
                    {hasUnsetTxhash &&
                        <button type="button" onClick={() => {
                            isCalledRef.current = false;
                            setResend(Math.random())

                        }} class="btn text-dark bg-warning w-40 mx-3 mt-3">Resend</button>
                    }
                    {hasCompleteTxhash &&
                        <button type="button" onClick={() => {
                            window.location.reload()
                        }} class="btn text-dark bg-warning w-40 mx-3 mt-3">Complete</button>
                    }
                </div>
            </div>

            {/* </div>
        </div> */}
        </>
    )
}
