import React, { useContext } from 'react';
import logoImg from '../images/logo.png'
import { BaseContext } from '../context/BaseProvider';
import youtubeImg from '../images/youtube.png'
import twitterImg from '../images/twitter.png'
import telegramImg from '../images/telegram.png'
import { Link, useLocation } from 'react-router-dom';

export default function Sidebar({ settingStats }) {
  const { setSidebar } = useContext(BaseContext);
  const { pathname } = useLocation()

  return (
    <aside class=" g-sidenav-pinned g-sidenav-show  sidenav bg-card navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 " id="sidenav-main">
      <div class="sidenav-header">
        <span onClick={() => setSidebar(false)} className='p-1 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-xl-none'>
          <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
        </span>
        <a class="d-flex justify-content-center m-0 navbar-brand" href="#sec" target="_blank">
          <img src={logoImg} class="navbar-brand-img" alt="main_logo" />
        </a>
      </div>
      <hr class="horizontal dark mt-0" />
      <div class="collapse navbar-collapse  w-auto mt-5" id="sidenav-collapse-main">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class={`nav-link ${pathname === '/token-create' ? 'active' : ''}`} href="#sec">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Create Token</span>
            </a>
          </li>
          <li class="nav-item">
            <Link class={`nav-link ${pathname === '/' ? 'active' : ''}`} to="/">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <i class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"></i>
              </div>
              <span class="nav-link-text ms-1">Multisender</span>
            </Link>
          </li>
        </ul>
      </div>
      <div class="sidenav-footer mx-3 absoulte m-0" style={{ position: "absolute", bottom: 20, left: 20 }}>
        <a href="https://t.me/mooncrypt_official" target='_blank'>
          <img className='mx-2' src={telegramImg} height="30px" width="30px" alt="telegram" />
        </a>
        <a href="https://x.com/mooncrypt_org" target='_blank'>
          <img className='mx-2' src={twitterImg} height="30px" width="30px" alt="twitter" />
        </a>
      </div>
    </aside>
  )
}
