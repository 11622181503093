import React from 'react';
import CodeMirror from "@uiw/react-codemirror";
import { Modal } from "react-bootstrap"
import { useDispatch, useSelector } from 'react-redux';
import { fetchStep1Validation, updateInvalidAddress, updateStep1ErrorModal, updateStep1Validation } from '../../redux/slices/MultisenderSlice';
import { useAccount } from 'wagmi';
import { DEFAULT_CHAIN, contract } from '../../helper/constant';


export default function Step1InvalidModal({ show }) {
    const dispatch = useDispatch();
    const invalidAddress = useSelector(state => state.multisender.invalidAddress);
    const isNFTIdError = useSelector(state => state.multisender.isNFTIdError);
    const { chain, address } = useAccount()

    return (
        <Modal show={show} onHide={() => dispatch(updateStep1ErrorModal())} size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                {isNFTIdError ? (
                    <Modal.Title className='example-title'>Invalid NFT IDS</Modal.Title>
                ) : (
                    <Modal.Title className='example-title'>Invalid Addresses</Modal.Title>
                )}

            </Modal.Header>
            <Modal.Body>

                <React.Fragment>
                    <CodeMirror
                        height="200px"
                        theme={'dark'}
                        placeholder="Insert address and amount,separate with comma"
                        style={{
                            border: "1px solid #444444",
                        }}
                        onChange={(e) => dispatch(updateInvalidAddress(e))}
                        value={invalidAddress}
                    />

                </React.Fragment>
                <div className='d-flex'>
                    <button
                        type="button"
                        onClick={() => {
                            dispatch(updateStep1Validation());
                            dispatch(fetchStep1Validation({
                                chainId: chain && chain.id && contract[chain.id] ? chain.id : DEFAULT_CHAIN,
                                address: address
                            }))
                        }}
                        className="btn text-dark bg-warning w-full mt-3 mx-2"
                    >
                        Fix Continue
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            dispatch(updateStep1Validation());
                            dispatch(fetchStep1Validation({
                                chainId: chain && chain.id && contract[chain.id] ? chain.id : DEFAULT_CHAIN,
                                address: address,
                                invalid_remove : true
                            }))
                        }}
                        className="btn text-dark bg-warning w-full mt-3  mx-2"
                    >
                        Delete and Continue
                    </button>
                </div>

            </Modal.Body>
        </Modal>
    )
}